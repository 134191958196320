import React, {
    useState } from 'react';

import { Waypoint } from 'react-waypoint';

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import styles from './services.module.scss';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import Navbar from '../navbar/navbar';
import Footer from '../footer/footer';

import logo from "static/images/logo_azul.png";

function Services() {

    const [one, setOne] = useState(styles.services_row)
    const [two, setTwo] = useState(styles.services_row)
    const [three, setThree] = useState(styles.services_row)
    const [four, setFour] = useState(styles.services_row)
    const [five, setFive] = useState(styles.services_row)
    const [six, setSix] = useState(styles.services_row)
    const [eight, setEight] = useState(styles.services_row)

    return (
        <div>
            <Navbar />
            <div className={ styles.container }>
                <div className={ styles.wrapper }>
                    <div className={ styles.title }>
                        <p>Servicios</p>
                        <p>
                            Los servicios ofrecidos por Hematológica, están enfocados en lograr un diagnóstico certero y oportuno 
                            de manera que el tratamiento de nuestros pacientes se inicie a la brevedad posible.
                        </p>
                    </div>
                    <hr />

                    <div className={ styles.title }>
                        <p>Efermedades de atención frecuente</p>
                    </div>

                    <Waypoint onEnter={() => setOne(styles.services_row  + " " + styles.animate)}>
                        <div className={ styles.services }>
                            <div className={ one }>
                                <div className={ styles.card}>
                                    <div className={ styles.image_container }>
                                        <div className={ styles.image_spacer }>
                                            <img src={ logo } />
                                        </div>
                                    </div>
                                    <p>Anemia</p>
                                    <hr />
                                    <div className={ styles.definition }>
                                        <p>
                                            Afección en la cual disminuyen los  glóbulos rojos sanos afectando  el transporte de oxígeno 
                                            a los tejidos del cuerpo.
                                        </p>
                                    </div>
                                    
                                </div>
                                
                                <div className={ styles.card}>
                                    <div className={ styles.image_container }>
                                        <div className={ styles.image_spacer }>
                                            <img src={ logo } />
                                        </div>
                                    </div>
                                    <p>Trombocitopenia</p>
                                    <hr />
                                    <div className={ styles.definition}>
                                        <p>
                                            Es una disminución en el número de plaquetas, su origen es multifactorial por lo que las causas deben ser diagnosticadas.
                                        </p>
                                        <p>
                                            Una cifra muy baja de plaquetas puede generar hemorragias y moretones.
                                        </p>
                                    </div>
                                </div>
                                
                                <div className={ styles.card}>
                                    <div className={ styles.image_container }>
                                        <div className={ styles.image_spacer }>
                                            <img src={ logo } />
                                        </div>
                                    </div>
                                    <p>Linfoma </p>
                                    <hr />
                                    <div className={ styles.definition}>
                                        <p>
                                            Es un tipo de cáncer que afecta el sistema linfático que comprende los ganglios linfáticos, 
                                            el bazo, el timo y la médula ósea.
                                        </p>
                                        <p>
                                            Se realiza una valoración médica y estadificación para iniciar el tratamiento 
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Waypoint>

                    <Waypoint onEnter={() => setTwo(styles.services_row  + " " + styles.animate)}>
                        <div className={ styles.services }>
                            <div className={ two }>
                                <div className={ styles.card}>
                                    <div className={ styles.image_container }>
                                        <div className={ styles.image_spacer }>
                                            <img src={ logo } />
                                        </div>
                                    </div>
                                    <p>Leucemias<br/>Agudas</p>
                                    <hr />
                                    <div className={ styles.definition}>
                                        <p>
                                            Son enfermedades cancerosas de la sangre donde proliferan células sanguineas inmaduras que se acumulan, disminuyendo la producción de las células
                                            sanguíneas normales.
                                        </p>
                                        <p>
                                            Existen varias formas de tratar estas
                                            patologías, dependiendo del tipo de leucemia aguda y criterios clínicos al momento del diagnóstico.
                                        </p>
                                    </div>
                                </div>

                                <div className={ styles.card}>
                                    <div className={ styles.image_container }>
                                        <div className={ styles.image_spacer }>
                                            <img src={ logo } />
                                        </div>
                                    </div>
                                    <p>Leucemias<br/>Crónicas</p>
                                    <hr />
                                    <div className={ styles.definition}>
                                        <p>
                                            Son enfermedades cancerosas de la sangre donde proliferan células sanguineas maduras que pueden generar alteraciones en la producción  
                                            de células sanguineas normales.
                                        </p>
                                        <p>
                                            Existen varias formas de tratar estas patologías, dependiendo del tipo de leucemia crónica y 
                                            criterios clínicos al momento del diagnóstico.
                                        </p>
                                        <p>
                                            Estas suelen manejarse por periodos largos con buena calidad de vida.
                                        </p>
                                    </div>
                                </div>

                                <div className={ styles.card}>
                                    <div className={ styles.image_container }>
                                        <div className={ styles.image_spacer }>
                                            <img src={ logo } />
                                        </div>
                                    </div>
                                    <p>Problemas de coagulación y hemorragias</p>
                                    <hr />
                                    <div className={ styles.definition}>
                                        <p>
                                            Estas alteraciones pueden ser patologías hereditarias o adquiridas con condiciones hemorragicas importantes, ya sea por 
                                            alteraciones de los factores de coagulación o por defectos plaquetarios.
                                        </p>
                                        <p>
                                            Tambien manejamos enfermedades que predisponen la formación de trombos anormales.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Waypoint>
                    
                    <hr />

                    <div className={ styles.title }>
                        <p>Procedimientos</p>
                        <p>
                            Los procedimientos practicados en Hematológica se realizan por médicos expertos en su campo y bajo los más altos estandares de calidad.
                        </p>
                    </div>

                    <Waypoint onEnter={() => setThree(styles.services_row  + " " + styles.animate)}>
                        <div className={ styles.services }>
                            <div className={ three}>
                                <div className={ styles.card}>
                                    <div className={ styles.image_container }>
                                        <div className={ styles.image_spacer }>
                                            <img src={ logo } />
                                        </div>
                                    </div>
                                    <p>Consulta Especializada</p>
                                    <hr />
                                    <div className={ styles.definition }>
                                        <p>
                                            Nuestros servicios incluyen consultas de diagnóstico y de seguimiento.
                                        </p>
                                    </div>
                                </div>
                                
                                <div className={ styles.card}>
                                    <div className={ styles.image_container }>
                                        <div className={ styles.image_spacer }>
                                            <img src={ logo } />
                                        </div>
                                    </div>
                                    <p>Toma de Constantes Vitales</p>
                                    <hr />
                                    <p>
                                        Cuantificación de los parametros fisiológicos que nos permiten conocer el estado de salud de nuestros pacientes.
                                    </p>
                                </div>
                                
                                <div className={ styles.card}>
                                    <div className={ styles.image_container }>
                                        <div className={ styles.image_spacer }>
                                            <img src={ logo } />
                                        </div>
                                    </div>
                                    <p>Aspirado de Médula Ósea</p>
                                    <hr />
                                    <p>
                                        Punción en la cadera para recolectar una muestra de tejido de médula ósea que se utiliza en múltiples estudios de laboratorio.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Waypoint>

                    <Waypoint onEnter={() => setFour(styles.services_row  + " " + styles.animate)}>
                        <div className={ styles.services }>
                            <div className={ four }>
                                <div className={ styles.card}>
                                    <div className={ styles.image_container }>
                                        <div className={ styles.image_spacer }>
                                            <img src={ logo } />
                                        </div>
                                    </div>
                                    <p>Biopsia<br/>de Hueso</p>
                                    <hr />
                                    <div className={ styles.definition }>
                                        <p>
                                            Recolección de una muestra osea del paciente que se utiliza en múltiples estudios de laboratorio.
                                        </p>
                                    </div>
                                </div>
                                
                                <div className={ styles.card}>
                                    <div className={ styles.image_container }>
                                        <div className={ styles.image_spacer }>
                                            <img src={ logo } />
                                        </div>
                                    </div>
                                    <p>Administración de Tratamientos</p>
                                    <hr />
                                    <p>
                                        Hematológica diseña y calcula los tratamientos personalizados requeridos para la recuperación de nuestros pacientes.
                                    </p>
                                    
                                </div>
                                
                                <div className={ styles.card}>
                                    <div className={ styles.image_container }>
                                        <div className={ styles.image_spacer }>
                                            <img src={ logo } />
                                        </div>
                                    </div>
                                    <p>Administración de Medicamentos</p>
                                    <hr />
                                    <p>
                                        En la clínica estamos capacitados para administrar hierro, tratamientos intravenosos, medicamentos intramusculares, 
                                        subcutáneos, quimioterapias e inmunoterapias.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Waypoint>

                    <hr />

                    <div className={ styles.title }>
                        <p>Servicios Externos</p>
                        <p>
                            Hematológica cuenta con acceso a los servicios brindados por el Hospital Ángeles Lomas 
                            lo cual permite realizar el seguimiento de nuestros pacientes haciendo uso de las 
                            mejores instalaciones hospitalarias en latinoamérica.
                        </p>
                    </div>

                    <Waypoint onEnter={() => setFive(styles.services_row  + " " + styles.animate)}>

                        
                        <div className={ styles.services }>
                            <div className={ five }>
                                
                                <div className={ styles.card}>
                                    <div className={ styles.image_container }>
                                        <div className={ styles.image_spacer }>
                                            <img src={ logo } />
                                        </div>
                                    </div>
                                    <p>Laboratorio Clínico</p>
                                    <hr />
                                    <p>
                                        Procesa muestras biológicas para ayudar a nuestros expertos a encontrar un diagnostico certero.
                                    </p>
                                </div>
                                
                                <div className={ styles.card}>
                                    <div className={ styles.image_container }>
                                        <div className={ styles.image_spacer }>
                                            <img src={ logo } />
                                        </div>
                                    </div>
                                    <p>Centro Oncológico</p>
                                    <hr />
                                    <p>
                                        En caso de ser requerido se cuenta con un centro oncológico donde los pacientes internados cuentan 
                                        con atención médica las 24 horas del día por enfermeras y hematólogos especialistas.
                                    </p>
                                </div>
                                
                                <div className={ styles.card}>
                                    <div className={ styles.image_container }>
                                        <div className={ styles.image_spacer }>
                                            <img src={ logo } />
                                        </div>
                                    </div>
                                    <p>Centro de Colecta</p>
                                    <hr />
                                    <p>
                                        Centro especializado en la recolección de células madre para realizar transplantes de médula ósea 
                                        autólogos y alogénicos.
                                    </p>
                                </div>
                            </div>
                        </div>
                    
                    </Waypoint>

                    <Waypoint onEnter={() => setSix(styles.services_row  + " " + styles.animate)}>
                        <div className={ styles.services }>
                            <div className={ six }>
                                
                                <div className={ styles.card}>
                                    <div className={ styles.image_container }>
                                        <div className={ styles.image_spacer }>
                                            <img src={ logo } />
                                        </div>
                                    </div>
                                    <p>Unidad de Transplantes</p>
                                    <hr />
                                    <p>
                                        Unidad especializada que se encarga de la evaluación, el estudio de compatibilidad, y la realización de transplantes de médula ósea.
                                    </p>
                                </div>
                                
                                <div className={ styles.card}>
                                    <div className={ styles.image_container }>
                                        <div className={ styles.image_spacer }>
                                            <img src={ logo } />
                                        </div>
                                    </div>
                                    <p>Estudios Genómicos en Hematología</p>
                                    <hr />
                                    <p>
                                        Secuenciación de última generación utilizando el sistema Illumina, con la cual se analiza el ADN y la secuenciación completa del 
                                        genoma de nuestros pacientes.
                                    </p>
                                    <p>
                                        Estudios citogenéticos como careotipo y determinación de mutaciones por FISH.
                                    </p>
                                </div>
                                
                                <div className={ styles.card}>
                                    <div className={ styles.image_container }>
                                        <div className={ styles.image_spacer }>
                                            <img src={ logo } />
                                        </div>
                                    </div>
                                    <p>Citometría de Flujo</p>
                                    <hr />
                                    <p>
                                        Inmunofenotipo: Es un estudio para diagnosticar y clasificar algunos tipos de cancer de células sanguineas.
                                    </p>
                                    <p>
                                        Enfermedad Mínima Residual: Estudio de seguimiento para monitorear el estado de la enfermedad de nuestros pacientes en tratamientos de neoplasias hematológicas.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Waypoint>
                    
                    <hr />

                    <div className={ styles.title }>
                        <p>Instalaciones</p>
                        <p>
                            Hematológica cuenta con instalaciones de primer nivel enfocadas en el confort de nuestros pacientes
                        </p>
                    </div>

                    <Waypoint onEnter={() => setEight(styles.facilities  + " " + styles.animate)}>
                        <div className={ eight }>
                            <Swiper
                                // install Swiper modules
                                modules={[Navigation, Pagination, A11y]}
                                spaceBetween={50}
                                slidesPerView={1}
                                navigation
                                pagination={{ clickable: true }}
                                centeredSlides={ true }
                                loop= { true }
                                >
                                <SwiperSlide>
                                    <img className="gallery-image" src="https://drive.google.com/uc?id=1i3-JwBUjjhfyTCgNO8KV_2lpgtWliC2M"/>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img className="gallery-image" src="https://drive.google.com/uc?id=1ElJuKvrXz7tNWoqmIMKMNM1e4lbf50LN"/>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img className="gallery-image" src="https://drive.google.com/uc?id=17_n09vhVM4xydWKhrYub9NhgNLVSeZxw"/>
                                </SwiperSlide>
                                
                                <SwiperSlide>
                                    <img className="gallery-image" src="https://drive.google.com/uc?id=1CAIBhnMagJHTsYM1FpBFr68-vV1M9LeM"/>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img className="gallery-image" src="https://drive.google.com/uc?id=1aOfVrBk7hTZqumIHrqchQYCzgkIQrIAR"/>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img className="gallery-image" src="https://drive.google.com/uc?id=1LWowH2xRF0RkL_OQzZWWWT43DRTpKA6y"/>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img className="gallery-image" src="https://drive.google.com/uc?id=1m-vgPcLX6mITM1bHqITFye-I7XbnrErT"/>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img className="gallery-image" src="https://drive.google.com/uc?id=1aA1b1vMP6LCPmudNqCWrRsHfAFBNW3eD"/>
                                </SwiperSlide>
                            </Swiper>
                        </div> 
                    </Waypoint>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default Services