import React, {
  Component } from 'react';


class Office extends Component {
  render() {
    return(
      <div className="container">
        <p className="subtitle mb-0">{ this.props.office.name }</p>

        <div className="columns">
          <div className="column is-4">
            <div className="section no-padding-bottom-mobile">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.411669421022!2d-99.28420988461299!3d19.394611146918777!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d206cc5d563ffd%3A0xf70cdf4e5a254cee!2sHospital%20Angeles%20Lomas!5e0!3m2!1ses-419!2smx!4v1614741722662!5m2!1ses-419!2smx" width="200" height="200" allowfullscreen="" loading="lazy"></iframe>
            </div>
          </div>
          <div className="column">
            <div className="section pt-5 paddingless-mobile">
              <div className="container is-flex">
                <div className="">
                  <span className="icon is-small mr-4">
                    <i className="fas fa-map-marker-alt"></i>
                  </span>
                </div>
                <div className="">
                  <p>{ this.props.office.location_1 }</p>
                  {
                    this.props.office.location_1 && <p>{ this.props.office.location_2 }</p>
                  }
                </div>
              </div>

              <div className="container my-3 is-flex">
                <div>
                  <span className="icon is-small mr-4">
                    <i className="fas fa-phone-alt"></i>
                  </span>
                </div>
                <div>
                  <p>{ this.props.office.phone }</p>
                </div>
              </div>

              <div className="container my-3 is-flex">
                <div>
                  <span className="icon is-small mr-4">
                    <i className="fas fa-stethoscope"></i>
                  </span>
                </div>
                <div className="content">
                  {
                    this.props.office.services.map((service) => <p className="my-0">{ service.name }</p>)
                  }
                </div>
              </div>

              <div className="container my-3 is-flex">
                <div>
                  <span className="icon is-small mr-4">
                    <i className="fas fa-money-bill-wave-alt"></i>
                  </span>
                </div>
                <div className="content">
                  <p className="my-0">Efectivo</p>
                  <p className="my-0">Tarjeta de débito</p>
                  <p className="my-0">Tarjeta de crédito</p>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    )
  }
}
export default Office
