import React, {
  Component } from 'react';

import './navbar.scss'

import logo from "../../static/images/ls_blanco.png";


class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navbarMenuFlag: false
    }
  }

  navbarMenuClass = () => {
    return "navbar-menu" + ((!this.state.navbarMenuFlag) ? "" : " is-active");
  }

  render() {
    return(
      <nav role="navigation" aria-label="main navigation">
        <div className="wrapper">
          <div className="logo">
            <a href="/">
              <img src={ logo } />
            </a>
          </div>

          <div className="elements">
            <div className="pages">
                <a href="/">Inicio</a>
                <a href="/servicios">Servicios</a>
                <a href="/equipo">Nuestro Equipo</a>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}
export default Navbar
