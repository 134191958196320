import React, {
  useState } from 'react';

import { Waypoint } from 'react-waypoint';

import Navbar from '../navbar/navbar';
import Footer from '../footer/footer';

import './landing.scss'

import values from "static/images/no_bg_values.png"


function Landing() {

  const [policyStyle, setPolicyStyle] = useState("")
  const [testimonyStyle, setTestimonyStyle] = useState("")
  const [locationStyle, setLocationStyle] = useState("")

  function bannerAnimationTrigger(value, setter) {
    if (!value.includes("animatedBackground")) {
      setter("animatedBackground")
    }
  }

    return (
      <div className="">
        <Navbar />

        <div className="hero landing">
          {/* animated background div */}
          <div className="animatedBackground">
          </div>

          <div className="hero-wrapper">
            <div className="hero-logo">
              <img  src="https://drive.google.com/uc?id=1voqLIgDjsuDhToYS-R7Z_vaPHX4NPtmt" />
            </div>
            <div className="hero-message">
              <p className="title">Hematológica es la clínica de alta especialidad en hematología con más experiencia en México.</p>
              <p className="subtitle">Estamos listos para poner nuestra experiencia a tu servicio</p>
            </div>
          </div>
        </div>

        <div className="our-mission-vision">
          <div className="wrapper">
            <div className="initial">
              <p className="title">Nuestro Objetivo</p>
              <p>
                Ofrecer los mejores y más novedosos cuidados basados en la evidencia,
                disponiendo de distintos profesionales formados y actualizados en los
                avances más recientes de la especialidad.
              </p>
              <div className="button-container">
                <a href="/servicios">
                  <p>
                    Conoce Nuestros Servicios
                  </p>
                </a>
              </div>
            </div>
            <div className="card">
              <p className="title">Misión</p>
              <hr />
              <p>
                Ser el centro dedicado a satisfacer de manera eficiente las necesidades
                de nuestros pacientes, mediante la atención personalizada e integral,
                de la más alta calidad, siempre con calidez y profesionalismo.
              </p>
              <p>
                Estamos convencidos de que mediante la práctica de la medicina basada
                en evidencias, la investigación básica y clínica conducen a encontrar
                mejores soluciones, generando un diagnóstico oportuno y facilitando
                un tratamiento preciso y eficaz.
              </p>
            </div>

            <div className="card">
              <p className="title">Visión</p>
              <hr />
              <p>
                Ser el centro hematológico de referencia a nivel nacional e internacional
                reconocido por la calidad de nuestros servicios médicos especializados
                y de investigación contando con una amplia red de proveedores de la más
                alta calidad.
              </p>
            </div>
          </div>
        </div>

        <Waypoint onEnter={() => bannerAnimationTrigger(policyStyle, setPolicyStyle) } >
          <div className="highlight-banner quality-politics">
            {/* Animated background */}
            <div className={ policyStyle }>
            </div>
            <div className="wrapper">
              <p className="title">Política de Calidad</p>
              <p>
                Mantenemos un sistema de gestión de la calidad eficiente y activo, adecuado tanto
                a las necesidades de la organización como a las de nuestros pacientes.
              </p>
              <p>
                Con el compromiso y participación de todo el equipo involucrado trabajamos para
                cumplir las expectativas de nuestros pacientes, fomentando siempre la mejora continua.
              </p>
            </div>
          </div>
        </Waypoint>

        <div className="team">
          <div className="wrapper">
            <div className="title">
              <p>Nuestro Equipo</p>
            </div>
            <div className="card-main">
              <div className="main-picture">
                <img src="https://drive.google.com/uc?id=1DaBxUfC-0cAN2c4a67Eyt0cZf5gc0UcY" alt="Placeholder image" />
              </div>
              <div className="main-info">
                <div className="info-header">
                  <p>Dr. Roberto Ovilla Martinez</p>
                  <p>Director General</p>
                  <p>Jefe de Hematología y Transplates del Hospital Ángeles Lomas</p>
                  <hr />
                </div>
                <div className="info-body">
                  <p>
                    Pionero de la hematología en México, cuenta con más de 30 años de experiencia
                    en el campo.
                  </p>
                  <p>
                    Graduado de Médico Cirujano por la Universidad Autónoma de Chiapas, con
                    especialidad en Hematología en el Centro Médico Nacional del IMSS en la
                    Ciudad de México y subespecialidad en Trasplante de Médula Ósea por la
                    Universidad de California en Los Ángeles, California.
                  </p>
                  <p>
                    Actualmente el doctor es referente a nivel internacional por su participación
                    en el desarrollo de protocolos y estudios clínicos para el lanzamiento de
                    nuevos esquemas terapéuticos.
                  </p>
                </div>
              </div>
            </div>

            <div className="small-cards">
              <div className="card">
                <img className="landing-portrait" src="https://drive.google.com/uc?id=1V-1S6-sLXIdj4NRD97cXAhjSzmPZH-1m" alt="Placeholder image" />
                <p>Dra. Carolina Carcova Tenorio</p>
                <p>Coordinadora de protocolos y ensayos clínicos</p>
              </div>
              <div className="card">
                <img className="landing-portrait" src="https://drive.google.com/uc?id=19a5gRb7pUsN_-jbprTp0TxFx0YRaRt3a" alt="Placeholder image" />
                <p>Dra. Nishalle Ramírez Muñiz</p>
                <p>Hematóloga</p>
              </div>
              <div className="card">
                  <img className="landing-portrait" src="https://drive.google.com/uc?id=1EsDSTnJNibP3VJHa51luo7dvPFS9VHJ1" alt="Placeholder image" />
                <p>Dr. Andric Cuevas Suarez</p>
                <p>Hematólogo</p>
              </div>

            </div>
            <div className="cta">
              <a>
                <p>Conoce a nuestro equipo</p>
              </a>
            </div>

          </div>
        </div>

        <Waypoint onEnter={ () => bannerAnimationTrigger(testimonyStyle, setTestimonyStyle) }>
          <div className="testimonies">
            {/* Animated background */}
            <div className={ testimonyStyle }>

            </div>
            <div className="wrapper">
              <p>Nuestros Casos de Éxito</p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipiscing elit eleifend,
                magnis himenaeos class id justo nec. Nunc sed praesent tristique
                malesuada pharetra dictumst taciti mauris, auctor purus netus at
                sociosqu sapien tellus ligula sociis, dictum orci urna erat justo
                maecenas feugiat.
              </p>
              <p>- Paciente Uno</p>
            </div>
          </div>
        </Waypoint>
        

        <div className="values">
          <div className="wrapper">
            <p>Nuestros Valores</p>
            <img src="https://drive.google.com/uc?id=1urPC8k_dSR3QDtvunk4rvCRQwn_rieGO" alt="Placeholder image"/>
          </div>
        </div>

        <Waypoint onEnter={ () => bannerAnimationTrigger(locationStyle, setLocationStyle) }>
          <div className="location">
            {/* animated background */}
            <div className={ locationStyle }>
            </div>

            <div className="wrapper">
              <p>Ubicación</p>
              <div className="columns">
                <div className="map">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.411669421022!2d-99.28420988461299!3d19.394611146918777!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d206cc5d563ffd%3A0xf70cdf4e5a254cee!2sHospital%20Angeles%20Lomas!5e0!3m2!1ses-419!2smx!4v1614741722662!5m2!1ses-419!2smx" allowFullScreen="" loading="lazy"></iframe>
                </div>
                <div className="info">
                  <div>
                    <div >
                      <p className="title">Dirección</p>
                      <p>Hospital Ángeles Lomas<br />Vialidad de la Barranca 240 <br />Colonia Hacienda de las Palmas<br/>Huixquilucan<br/>Estado de México
                      </p>
                      <p>
                        Torre de Consultorios<br/>Piso 8
                      </p>
                      <p>Consultorio 830
                      </p>
                    </div>
                    <div>
                      <p className="title">Horarios
                      </p>
                      <p><span>Servicio</span>: Lunes a Viernes de 08:00 a 20:00 hrs
                      </p>
                      <p><span>Consulta</span>: Lunes a Viernes de 10:00 a 14:00 y de 17:00 a 20:00
                      </p>
                    </div>
                    <div>
                      <p className="title">Teléfonos de contacto
                      </p>
                      <p className="phone">5552469424</p>
                      <p>5552469670</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Waypoint>
        

        <div className="partners">
          <div className="wrapper">
            <p>Nuestros Colaboradores</p>
            <img src="https://drive.google.com/uc?id=1Hqy7gnthfEM4jZqEsaz9kQx9XFbmAJlT" />
          </div>
        </div>

        <Footer />

      </div>
    )
}
export default Landing
