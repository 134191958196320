import React, {
  useState } from 'react';

import { Waypoint } from 'react-waypoint';

import styles from "./team.module.scss";

import Navbar from '../navbar/navbar';
import Footer from '../footer/footer';

import facebook_icon from "../../static/images/icons/facebook-f.svg";
import instagram_icon from "../../static/images/icons/instagram.svg";
import twitter_icon from "../../static/images/icons/twitter.svg";


function Team() {

  const [one, setOne] = useState(styles.card)
  const [two, setTwo] = useState(styles.card)
  const [three, setThree] = useState(styles.card)
  const [four, setFour] = useState(styles.card)
  const [five, setFive] = useState(styles.card)
  const [six, setSix] = useState(styles.card)
  const [seven, setSeven] = useState(styles.card)
  
  return (
    <div>
      <Navbar />
      <div className={ styles.container }>
        <div className={ styles.wrapper }>
          <div className={ styles.title }>
            <p>Nuestro equipo</p>
            <p>En Hematologica contamos con especialistas expertos en sus campos que están listos para atenderte.</p>
          </div>

          <div className={ styles.cards }>

            <Waypoint onEnter={() => setOne(styles.card + " " + styles.animate)}>
              <div className={ one }>
                <div className={ styles.picture }>
                  <img src="https://drive.google.com/uc?id=1DaBxUfC-0cAN2c4a67Eyt0cZf5gc0UcY" />
                </div>
                <div className={ styles.info }>
                  <p>Dr. Roberto Ovilla Martínez</p>
                  <p>Director General</p>
                  <p>Jefe de Hematología y Transplates del Hospital Ángeles Lomas</p>
                  <hr />
                  <div>
                    <p>
                      Graduado de Médico Cirujano por la Universidad Autónoma de Chiapas, con especialidad en Hematología en el Centro Médico Nacional 
                      del IMSS en la Ciudad de México y subespecialidad en Trasplante de Médula Ósea por la Universidad de California en Los Ángeles, 
                      California.
                    </p>
                    <p>
                      Fundador del Servicio de Hematología en el Hospital General Regional No. 1 Gabriel Mancera en la CDMX.
                    </p>
                    <p>
                      Coordinador de Transplates de Médula Ósea del Instituto Mexicano del Seguro Social del año 1996 al 2001.
                    </p>
                    <p>
                      Socio fundador y presidente del Grupo Cooperativo de Hemopatías Malignas, conformado por los mejores especialistas 
                      del país, que tiene como objetivo principal el desarrollo de prácticas médicas que beneficien de manera más efectiva 
                      a los pacientes por medio de la investigación.
                    </p>
                  </div>
                </div>
              </div>
            </Waypoint>
            
            <Waypoint onEnter={() => setTwo(styles.card + " " + styles.animate)}>
              <div className={ two }>
                <div className={ styles.picture }>
                  
                  <img src="https://drive.google.com/uc?id=19a5gRb7pUsN_-jbprTp0TxFx0YRaRt3a" />
                </div>
                <div className={ styles.info }>
                  <p>Dra. Nishalle Ramírez Muñiz</p>
                  <p>Hematóloga</p>
                  <div className={ styles.social_networks}>
                    <a>
                    <img src={ instagram_icon } alt="" />
                    </a><a>
                    <img src={ facebook_icon } alt="" />
                    </a><a>
                    <img src={ twitter_icon } alt="" />
                    </a>
                  </div>
                  <hr />
                  <div>
                    <p>
                      Médica Hematóloga con formación en Medicina General en el Instituto Politécnico Nacional
                    </p>
                    <p>
                      Completó la especialidad de Hematología en el Hospital General de México avalado por la Universidad 
                      Nacional Autónoma de México.
                    </p>
                    <p>
                      Profesionalmente se caracteriza por su gran calidez humana y su compromiso con el paciente.
                    </p>
                    <p>
                      Cuenta con una amplia experiencia en el manejo de patologías hematológicas benignas y malignas.
                    </p>
                  </div>
                </div>
              </div>
            </Waypoint>

            
            <Waypoint onEnter={() => setThree(styles.card + " " + styles.animate)}>
              <div className={ three }>
                <div className={ styles.picture }>
                  <img src="https://drive.google.com/uc?id=1EsDSTnJNibP3VJHa51luo7dvPFS9VHJ1" />
                </div>
                <div className={ styles.info }>
                  <p>Dr. Andric Cuevas Suarez</p>
                  <p>Hematólogo</p>
                  <div className={ styles.social_networks}>
                    <a>
                    <img src={ instagram_icon } alt="" />
                    </a><a>
                    <img src={ facebook_icon } alt="" />
                    </a><a>
                    <img src={ twitter_icon } alt="" />
                    </a>
                  </div>
                  <hr />
                  <div>
                    <p>
                      Médico Hematólogo con formación como Médico General por la Universidad de Baja California Norte.
                    </p>
                    <p>
                      Completó la especialidad de Hematología en el Hospital General de México avalado por la Universidad Nacional
                      Autónoma de México.
                    </p>
                    <p>
                      Cuenta con amplia experiencia en el manejo de patologías hematológicas benignas y malignas.
                    </p>
                    <p>
                      Se caracteriza por su gran calidez humana, profesionalismo y compromiso con el paciente.
                    </p>
                  </div>

                </div>
              </div>
            </Waypoint>
            
            <Waypoint onEnter={() => setFour(styles.card + " " + styles.animate)}>
              <div className={ four }>
                <div className={ styles.picture }>
                  <img src="https://drive.google.com/uc?id=1V-1S6-sLXIdj4NRD97cXAhjSzmPZH-1m" />
                </div>
                <div className={ styles.info }>
                  <p>Dra. Carolina Carcoba Tenorio</p>
                  <p>Coordinadora de protocolos y ensayos clínicos</p>
                  <div className={ styles.social_networks}>
                    <a>
                    <img src={ instagram_icon } alt="" />
                    </a><a>
                    <img src={ facebook_icon } alt="" />
                    </a><a>
                    <img src={ twitter_icon } alt="" />
                    </a>
                  </div>
                  <hr />
                  <div>
                    <p>
                      Especialista en coordinación y logística de protocolos de investigaciones internacionales.
                    </p>
                    <p>
                      Gracias a su trabajo, Hematológica ha participado en protocolos de investigación que incluyen enfermedades como 
                      mieloma múltiple, síndrome mielodisplásico, linfomas hodgkin y no hodgkin, leucemias agudas y leucemias 
                      crónicas.
                    </p>
                  </div>
                </div>
              </div>
            </Waypoint>

            <Waypoint onEnter={() => setFive(styles.card + " " + styles.animate)}>
              <div className={ five }>
                <div className={ styles.picture }>
                  <img src="https://drive.google.com/uc?id=1HiceaVtYWcITz7H0jWAFxuNTxgVIjnjB" />
                </div>
                <div className={ styles.info }>
                  <p>Lic. Carolina Itzel Luna Cabrera</p>
                  <p>Enfermera</p>
                  <div className={ styles.social_networks}>
                    <a>
                    <img src={ instagram_icon } alt="" />
                    </a><a>
                    <img src={ facebook_icon } alt="" />
                    </a><a>
                    <img src={ twitter_icon } alt="" />
                    </a>
                  </div>
                  <hr />
                  <div>
                    <p>
                      Nuestro equipo de enfermería se caracteriza por poseer una gran calidad humana solo superado por su ejecución técnica.
                    </p>  
                    <p>
                      Experta en toma de muestras 
                      y administración de múltiples fármacos de especialidad como factores estimulantes de granulocitos, 
                      eritropoyetina, quimioterapia e inmunoterapia.
                    </p>
                  </div>
                </div>
              </div>
            </Waypoint>

            <Waypoint onEnter={() => setSix(styles.card + " " + styles.animate)}>
              <div className={ six }>
                <div className={ styles.picture }>
                  <img src="https://drive.google.com/uc?id=1RcV74e5L3CoQ3Lm7DcwjEw8HVhl-_qlQ" />
                </div>
                <div className={ styles.info }>
                  <p>Lic. Irina Nicolas García</p>
                  <p>Administración</p>
                  <div className={ styles.social_networks}>
                    <a>
                    <img src={ instagram_icon } alt="" />
                    </a><a>
                    <img src={ facebook_icon } alt="" />
                    </a><a>
                    <img src={ twitter_icon } alt="" />
                    </a>
                  </div>
                  <hr />
                  <div>
                    <p>
                      Administradora general de Hematológica.
                    </p>
                    <p>
                      Su labor consiste en coordinar las actividades y operaciones de la oficina y la clínica para asegurar la eficiencia y el 
                      cumplimiento de nuestras politicas de calidad.
                    </p>
                    <p>
                      Así mismo se encarga de la relación con nuestros proveedores para que nuestros pacientes tengan todo lo necesario para sus 
                      tratamientos al alcance.
                    </p>
                  </div>
                </div>
              </div>
            </Waypoint>

            <Waypoint onEnter={() => setSeven(styles.card + " " + styles.animate)}>
              <div className={ seven }>
                <div className={ styles.picture }>
                  <img src="https://drive.google.com/uc?id=1HP5V6ufJwSANfN6bYYgzuPXCG88ZJ6Ki" />
                </div>
                <div className={ styles.info }>
                  <p>Lic. Leticia Nicolas Hernández</p>
                  <p>Recepción</p>
                  <div className={ styles.social_networks}>
                    <a>
                    <img src={ instagram_icon } alt="" />
                    </a><a>
                    <img src={ facebook_icon } alt="" />
                    </a><a>
                    <img src={ twitter_icon } alt="" />
                    </a>
                  </div>
                  <hr />
                  <div>
                    <p>
                      Es el punto de contacto de nuestros pacientes.
                    </p>
                    <p>
                      Su trabajo incluye brindar atención al personal y a los pacientes en sus requerimientos de información; 
                      así mismo mantiene el control y la administración de la agenda y la recepción de la clínica.
                    </p>
                    <p>
                      De igual manera brinda apoyo en actividades encomendadas para asegurar que el trato hacia nuestros pacientes siempre sea 
                      de primer nivel.
                    </p>
                  </div>
                </div>
              </div>
            </Waypoint>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
export default Team
