import React, {
  Component } from 'react';
import Axios from 'axios';

import api_url from './utils/urls';

import fbIcon from "../static/images/icons/facebook-f.svg";
import ttIcon from "../static/images/icons/twitter.svg";
import igIcon from "../static/images/icons/instagram.svg";

import Office from './office';
import Navbar from './navbar/navbar';
import Footer from './footer/footer';


class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: {},
      videocall_selected: false,
      formData: {
        contact_preference: "EM",
        office: 1
      },
      errors: [],
      contactModal: false,
      socialNetworksURLs: {
        "fb": "https://facebook.com/",
        "ig": "https://instagram.com/",
        "tw": "https://twitter.com/",
        "li": "https://linkedin.com/"
      },
      socialNetworksIcons: {
        "fb": "fab fa-facebook",
        "ig": "fab fa-instagram",
        "tw": "fab fa-twitter",
        "li": "fab fa-linkedin-in"
      }
    }
  }

  componentDidMount() {
    Axios.get(
      api_url + "profile/" + this.props.router.match.params.uuid + '/'
    )
    .then((res) => {
      this.setState({
        profile: res.data
       })
    })
  }

  setInputStyle = (field) => {
    return "input" + ((Object.keys(this.state.errors).includes(field)) ? " is-danger" : "")
  }

  renderErrorMessages = (field) => {
    if(Object.keys(this.state.errors).includes(field)) {
      return this.state.errors[field].map( (message, index) => {
        return <p key={ index } className="help is-danger">{ message }</p>
      })
    }
  }

  formDataUpdate = (event) => {
    let formData = { ...this.state.formData }
    formData[event.target.name] = event.target.value
    this.setState({ formData })
  }

  formSubmit = () => {
    let body = { ...this.state.formData }
    if(this.state.videocall_selected) {
      delete body.office
    }
    // agregando el profile
    body["profile"] = this.state.profile.id

    Axios.post(
      api_url + "appointments/",
      body
    )
    .then((res) => {
      this.setState({ contactModal: !this.state.contactModal })
    })
    .catch((res) => {
      this.setState({ errors: res.response.data })
    })
  }

  render() {
    return(
      <div className="">
        <Navbar />
        <div className="section paddingless-mobile">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-two-thirds">
                <div className="section">
                  <div className="card">
                    <div className="card-content">
                      <div className="columns">
                        <div className="column is-4">
                            <img className="profile-picture" src={this.state.profile.profile_picture } />
                        </div>
                        <div className="column is-flex is-flex-direction-column is-justify-content-center">
                          <p className="title">{ this.state.profile.name }</p>
                          <p className="subtitle mb-0">{ this.state.profile.fields && this.state.profile.fields.map( (field) => <span>{ field.name }</span> ) }</p>
                          <p>CDMX</p>
                          <div className="tags are-medium mt-4">
                            <span className="tag"><span className="tag-icon"><i className="fas fa-user-md"></i></span>Consultas presenciales</span>
                            <span className="tag"><span className="tag-icon"><i className="fas fa-video"></i></span>Videoconsultas</span>
                          </div>
                          <p>Redes Sociales</p>
                          <div className="buttons pt-2">
                            {
                              this.state.profile.social_networks && this.state.profile.social_networks.map((network) => {
                                return(
                                  <a className="button p-3" rel="nofollow" target="_blank" href={ `${this.state.socialNetworksURLs[network.network]}${network.profile_name}/` }>
                                    <i className={ this.state.socialNetworksIcons[network.network] }></i>
                                  </a>
                                )
                              })
                            }
                          </div>
                        </div>
                      </div>
                      <hr />

                      <div className="section py-4 paddingless-mobile">
                        <p className="title is-5 mb-4">Sobre el especialista</p>
                        {
                          this.state.profile.about_me
                        }
                      </div>
                      <hr />

                      <div className="section py-4 paddingless-mobile">
                        <p className="title is-5 mb-4">Consultorios</p>
                          <article className="message is-link">
                            <div className="message-body">
                              Este especialista también ofrece consultas a través de videollamadas.
                            </div>
                          </article>
                        {
                          this.state.profile.offices && this.state.profile.offices.map( (office) => {
                            return(
                              <Office office={office} />
                            )
                          })
                        }
                      </div>
                      <hr />

                      <div className="section py-4 paddingless-mobile">
                        <p className="title is-5 mb-4">Experiencia</p>
                        <div className="section mx-0 px-0 py-4">
                          <p className="subtitle mb-0">Formación</p>
                          <div className="content">
                            <ul>
                              {
                                this.state.profile.formations && this.state.profile.formations.map((formation) => {
                                  return (
                                    <li>
                                      <p className="subtitle is-5 mb-0">{ formation.name }</p>
                                      <p className="subtitle is-6 mb-0">{ formation.institution }</p>
                                      { formation.location && <p className="subtitle is-6 mb-0">{ formation.location }</p> }
                                      { formation.year && <p className="subtitle is-6 mb-0">{ formation.year }</p> }
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </div>
                        </div>

                        <div className="section mx-0 px-0 py-4">
                          <p className="subtitle is-5 mb-0">Especialidades</p>
                          <div className="content">
                            <ul>
                              {
                                this.state.profile.habilities && this.state.profile.habilities.map((hability) => {
                                  return (
                                    <li>{ hability.name }</li>
                                  )
                                })
                              }
                            </ul>
                          </div>
                        </div>

                        <div className="section mx-0 px-0 py-4">
                          <p className="subtitle mb-0">Enfermedades tratadas</p>
                          <div className="content">
                            <ul>
                              {
                                this.state.profile.diseases && this.state.profile.diseases.map((disease) => {
                                  return (
                                    <li>{ disease.name }</li>
                                  )
                                })
                              }
                            </ul>
                          </div>
                        </div>
                      </div>
                      <hr />

                      <div className="section py-4 paddingless-mobile">
                        <p className="title is-5 mb-4">Opiniones</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="column">
                <div className="section">
                  <div className="card">
                    <header className="card-header">
                      <p className="card-header-title">Agenda una cita</p>
                    </header>
                    <div className="card-body">
                      <div className="section py-4">
                        <div className="tabs">
                          <ul>
                            <li className={ !this.state.videocall_selected && "is-active"} onClick={ () => { this.setState({videocall_selected: !this.state.videocall_selected }) } }><a>Visita al consultorio</a></li>
                            <li className={ this.state.videocall_selected && "is-active" } onClick={ () => { this.setState({videocall_selected: !this.state.videocall_selected }) } }><a>Consulta en línea</a></li>
                          </ul>
                        </div>
                      </div>

                      <div className="section py-2" onChange={ (event) => this.formDataUpdate(event) } >
                        {
                          (!this.state.videocall_selected) ? (

                            <div className="field">
                              <label className="label">Consultorio</label>
                                <div className="control">
                                  <div className="select is-fullwidth">
                                    <select>
                                      {
                                        this.state.profile.offices && this.state.profile.offices.map((office) =>  <option value={ office.id }>{ office.name }</option> )
                                      }
                                    </select>
                                  </div>
                                </div>
                            </div>
                          ) : (
                            <article className="message is-link">
                              <div className="message-body">
                                Una vez confirmada tu cita recibirás el link para tu consulta
                              </div>
                            </article>
                          )
                        }
                        <div className="field">
                          <label className="label">Nombre</label>
                          <div className="control">
                            <input className={ this.setInputStyle("contact_name") } type="text" name="contact_name" placeholder="Nombre del paciente" />
                            { this.renderErrorMessages("contact_name") }
                          </div>
                        </div>
                        <div className="field">
                          <label className="label">Fecha</label>
                          <div className="control">
                            <input className={ this.setInputStyle("date") } type="datetime-local" name="date" />
                            { this.renderErrorMessages("date") }
                          </div>
                        </div>
                        <div className="field">
                          <label className="label">Correo Electrónico</label>
                          <div className="control">
                            <input className={ this.setInputStyle("contact_email") } type="text" name="contact_email" placeholder="Correo de contacto"/>
                            { this.renderErrorMessages("contact_email") }
                          </div>
                        </div>
                        <div className="field">
                          <label className="label">Teléfono</label>
                          <div className="control">
                            <input className={ this.setInputStyle("contact_phone") } type="text" name="contact_phone" placeholder="Teléfono de contacto"/>
                            { this.renderErrorMessages("contact_phone") }
                          </div>
                        </div>
                        <div className="field">
                          <label className="label">¿Como te contactamos para confirmar tu cita?</label>
                          <div className="control">
                            <div className="select is-fullwidth">
                              <select name="contact_preference">
                                <option value="EM">Correo electrónico</option>
                                <option value="LL">Llamada</option>
                                <option value="WA">Whatsapp</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="section px-0 py-4">
                          <div className="control">
                            <button className="button is-link is-fullwidth" onClick={ () => this.formSubmit() }>Solicita tu cita</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />

        <div className={(!this.state.contactModal) ? "modal": "modal is-active" }>
          <div className="modal-background"></div>
          <div className="modal-content">
            <div className="section is-modal-icon py-5">
              <p className="modal-icon">
                <i className="far fa-check-circle"></i>
              </p>
            </div>
            <div class="box is-modal-content">
              <div className="section">
                <p className="title is-4 has-text-centered">¡Tu solicitud de cita ha sido enviada!</p>
                <p className="has-text-centered">A la brevedad nos pondremos en contacto contigo para confirmar tu cita a través del medio que hayas seleccionado</p>
              </div>
              <div className="section pt-0 pb-5">
                <div className="buttons is-centered">
                  <button className="button is-success is-outlined" onClick={ () => window.location.reload() }>¡Entendido!</button>
                </div>
              </div>
            </div>

          </div>
          <button className="modal-close is-large" aria-label="close" onClick={ () =>  window.location.reload() }></button>
        </div>

      </div>
    )
  }
}
export default Profile
