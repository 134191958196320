import React, {
  Component } from 'react';

import styles from './footer.module.scss';

import facebook_icon from "../../static/images/icons/facebook-f.svg";
import instagram_icon from "../../static/images/icons/instagram.svg";
import twitter_icon from "../../static/images/icons/twitter.svg";

class Footer extends Component {
  render() {
    return (
      <footer className={ styles.footer }>
        <div className={ styles.wrapper }>
          <div className={ styles.primary }>
            <p className={ styles.header }>Hematológica</p>
            <p>Clínica de alta especialidad en Hematología</p>
            <p>Hospital Ángeles Lomas</p>
            <p>Torre de Consultorios<br />Piso 8</p>
            <p>Consultorio 830</p>
            <p>Vialidad de la Barranca 240 <br />Colonia Hacienda de las Palmas<br/>Huixquilucan<br/>Estado de México</p>

          </div>
          <div className={ styles.secondary }>
            <p className={ styles.header }>Servicios</p>
            <a className={ styles.footer_link }>Nuestros Servicios</a>
          </div>
          <div className= { styles.secondary }>
            <p className={ styles.header }>Acerca de nosotros</p>
            <a className={ styles.footer_link }>Nuestro Equipo</a>
            <a className={ styles.footer_link }>Nuestras instalaciones</a>
            <a className={ styles.footer_link }>Reportar un problema</a>
            <a className={ styles.footer_link }>FAQ</a>
          </div>
          <div className={ styles.secondary }>
            <p className={ styles.header }>Contacto</p>
            <p>Correo Electrónico:<br />contacto@hematologica.com.mx</p>
            <p>Teléfonos: <br/>5552469424<br/>5552469670</p>
            
            <p>Redes Sociales:</p>
            <div className={ styles.social_networks}>
              <a className={ styles.social_network }>
                <img src={ instagram_icon } alt="" />
              </a>
              <a className={ styles.social_network }>
                <img src={ facebook_icon } alt="" />
              </a>
              <a className={ styles.social_network }>
                <img src={ twitter_icon } alt="" />
              </a>
            </div>
          </div>
        </div>
        <hr />
        <div className={ styles.wrapper }>
          <div>
            <a className={ styles.footer_link }>Aviso de privacidad</a>
          </div>
          <div className={ styles.text_right }>
            <p>© 2021 Hematológica. Todos los derechos reservados.</p>
            <p>Desarrollado con &#10084; en Taller de Código Casa Gris.</p>
          </div>
        </div>
      </footer>
    )
  }
}
export default Footer
