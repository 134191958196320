import React, {
  Component } from 'react';

import {
  Router,
  Route } from 'react-router-dom'

import history from './components/utils/history';

import Landing from './components/landing/landing';
import Profile from './components/profile';
import Review from './components/reviews';
import Services from './components/services/services';
import Team from './components/team/team';
import DrOvilla from 'components/dr_ovilla/dr_ovilla';
import DraRamirez from 'components/dra_ramirez/dra_ramirez';

import './static/styles/App.scss';

class App extends Component {
  render() {
    return (
      <div className="App">
        <Router history={ history }>
          <Route exact path="/">
            <Landing />
          </Route>
          <Route exact path="/servicios">
            <Services />
          </Route>
          <Route exact path="/equipo">
            <Team />
          </Route>
          <Route exact path="/especialistas/dr_roberto_ovilla">
            <DrOvilla />
          </Route>
          <Route exact path="/especialistas/dra_nishalle_ramirez">
            <DraRamirez />
          </Route>
          <Route
            exact path="/opiniones/:uuid"
            render={(matchProps) => <Review router={ matchProps } />}
          />

        </Router>
      </div>
    )
  }
}

export default App;
