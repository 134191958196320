import React, {
  Component } from 'react';
import Axios from 'axios';
import Cookies from 'js-cookie'

import api_url from './utils/urls';

import logo from "../static/images/ls.png";
import fbIcon from "../static/images/icons/facebook-f.svg";
import ttIcon from "../static/images/icons/twitter.svg";
import igIcon from "../static/images/icons/instagram.svg";

import Navbar from './navbar/navbar';
import Footer from './/footer/footer';

class Review extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: {},
      formData: {},
      errors: {},
      reviewModal: false,
      socialNetworksURLs: {
        "fb": "https://facebook.com/",
        "ig": "https://instagram.com/",
        "tw": "https://twitter.com/",
        "li": "https://linkedin.com/"
      },
      socialNetworksIcons: {
        "fb": "fab fa-facebook",
        "ig": "fab fa-instagram",
        "tw": "fab fa-twitter",
        "li": "fab fa-linkedin-in"
      }
    }
  }

  setInputStyle = (field) => {

    if(field === "message") {
      return "textarea" + ((Object.keys(this.state.errors).includes(field)) ? " is-danger" : "")
    } else {
      return "input" + ((Object.keys(this.state.errors).includes(field)) ? " is-danger" : "")
    }

  }

  renderErrorMessages = (field) => {
    if(Object.keys(this.state.errors).includes(field)) {
      return this.state.errors[field].map( (message, index) => {
        return <p key={ index } className="help is-danger">{ message }</p>
      })
    }
  }

  componentDidMount() {
    Axios.get(
      api_url + "profile/" + this.props.router.match.params.uuid + '/',
      {
        headers: {
          "X-CSRFToken": Cookies.get('csrftoken')
        }
      }
    )
    .then((res) => {
      this.setState({
        profile: res.data
       })
    })
  }

  formDataUpdate = (event) => {
    let formData = { ...this.state.formData }
    formData[event.target.name] = event.target.value
    this.setState({ formData })
  }

  formSubmit = () => {
    let body = { ...this.state.formData }
    // agregando el profile
    body["profile"] = this.state.profile.id

    Axios.post(
      api_url + "reviews/",
      body,
      {
        headers: {
          "X-CSRFToken": Cookies.get('csrftoken')
        }
      }

    )
    .then((res) => {
      this.setState({ reviewModal: !this.state.reviewModal })
    })
    .catch((res) => {
      this.setState({ errors: res.response.data })
    })
  }

  render() {
    return(
      <div className="">
        <Navbar />
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-two-thirds">
              <div className="section pb-0">
                <div className="card">
                  <div className="card-content">
                    <div className="container">
                      <img className="centered" src={ logo } />
                    </div>
                    <hr />
                    <div className="section">
                      <p className="title has-text-centered">¡Hola!</p>
                      <p className="has-text-centered">
                        Sabemos que recientemente has sido atendido por uno de
                        nuestros especialistas y para Hematológica es muy importante
                        tu opinión, por eso te agradecemos de antemano que nos cuentes
                        acerca de tu experiencia con nuestro especialista.
                      </p>
                    </div>
                    <hr />
                    <div className="section profile-section py-4">
                      <div className="columns">
                        <div className="column is-4">
                            <img className="profile-picture" src={this.state.profile.profile_picture } />
                        </div>
                        <div className="column is-flex is-flex-direction-column is-justify-content-center">
                          <p className="title">{ this.state.profile.name }</p>
                          <p className="subtitle mb-0">{ this.state.profile.fields && this.state.profile.fields.map( (field) => <span>{ field.name }</span> ) }</p>
                          <p>CDMX</p>
                          <div className="tags are-medium mt-4">
                            <span className="tag"><span className="tag-icon"><i className="fas fa-user-md"></i></span>Consultas presenciales</span>
                            <span className="tag"><span className="tag-icon"><i className="fas fa-video"></i></span>Videoconsultas</span>
                          </div>
                          <p>Redes Sociales</p>
                          <div className="buttons pt-2">
                            {
                              this.state.profile.social_networks && this.state.profile.social_networks.map((network) => {
                                return(
                                  <a className="button p-3" rel="nofollow" target="_blank" href={ `${this.state.socialNetworksURLs[network.network]}${network.profile_name}/` }>
                                    <i className={ this.state.socialNetworksIcons[network.network] }></i>
                                  </a>
                                )
                              })
                            }
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="section form-section">
                      <p className="subtitle has-text-centered">¡Muchas gracias por compartir tu experiencia!</p>
                      <div className="card review-form is-shadowless">

                        <div className="card-body ">

                          <div className="section py-2" onChange={ (event) => this.formDataUpdate(event) } >
                            <div className="field pt-2">
                              <label className="label">Nombre</label>
                              <div className="control">
                                <input className={ this.setInputStyle("name") } type="text" name="name" placeholder="Nombre del paciente" />
                                { this.renderErrorMessages("name") }
                              </div>
                            </div>
                            <div className="field">
                              <label className="label">Correo Electrónico <span className="subtitle is-7 pl-1">(opcional)</span></label>
                              <div className="control">
                                <input className={ this.setInputStyle("email") } type="text" name="email" placeholder="Correo electrónico" />
                                { this.renderErrorMessages("email") }
                              </div>
                            </div>
                            <div className="field">
                              <label className="label">Opinión</label>
                              <div className="control">
                                <textarea className={ this.setInputStyle("message") } name="message" placeholder="Cuentanos tu experiencia con Hematológica"></textarea>
                                { this.renderErrorMessages("email") }
                              </div>
                            </div>
                            <div className="section px-0 py-4">
                              <div className="control">
                                <button className="button is-link is-fullwidth" onClick={ () => this.formSubmit() }>Enviar tu opinión</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />

        <div className={(!this.state.reviewModal) ? "modal": "modal is-active" }>
          <div className="modal-background" onClick={ () =>  window.location.reload() }></div>
          <div className="modal-content">
            <div className="section is-modal-icon py-5">
              <p className="modal-icon">
                <i className="far fa-check-circle"></i>
              </p>
            </div>
            <div class="box is-modal-content">
              <div className="section">
                <p className="title is-4 has-text-centered">¡Tu opinión ha sido enviada!</p>
                <p className="has-text-centered">Muchas gracias por compartir tu experiencia en hematologica, la tomaremos en cuenta para seguir brindandote la mejor atención.</p>
              </div>
              <div className="section pt-0 pb-5">
                <div className="buttons is-centered">
                  <button className="button is-success is-outlined" onClick={ () => window.location.reload() }>¡Entendido!</button>
                </div>
              </div>
            </div>

          </div>
          <button className="modal-close is-large" aria-label="close" onClick={ () =>  window.location.reload() }></button>
        </div>



      </div>
    )
  }


}
export default Review
