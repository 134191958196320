import styles from './dra_ramirez.module.scss';

function DraRamirez() {
    return (
        <div className={ styles.wrapper}>
            <div className={ styles.header }>
                <div className={ styles.logo}>
                    <img src="https://drive.google.com/uc?id=16vz40RwpwD_JSKwexOg8H2-wLcPvxmAr" alt="" />
                </div>
            </div>
            <div className={ styles.info }>
                <div className={ styles.profile_picture_wrapper }>
                    <div className={ styles.profile_picture}>
                        <img src="https://drive.google.com/uc?id=19a5gRb7pUsN_-jbprTp0TxFx0YRaRt3a" alt="" />
                    </div>
                </div>
                <div className={ styles.profile_info }>
                    <div className={ styles.title }>
                        <p className={ styles.name }>Dra. Nishallé Ramirez Muñiz</p>
                        <p>Hematóloga en <a href="https://www.hematologica.com.mx/" target="_blank">Hematológica</a></p>
                        <p>Coordinadora en Be The Match México</p>
                    </div>
                    <hr />
                    <div className={ styles.contact }>
                        <p className={ styles.section }>Contacto</p>
                        <div className={ styles.field }>
                            <i className="fas fa-phone-alt"></i>
                            <p>5552469424</p>
                        </div>
                        <div className={ styles.field }>
                            <i className="fas fa-phone-alt"></i>
                            <p>5552469670</p>
                        </div>
                        <div className={ styles.field }>
                            <i className="far fa-envelope"></i>
                            <p>contacto@hematologica.com.mx</p>
                        </div>
                        <div className={ styles.field }>
                            <i className="fab fa-instagram"></i>
                            <a href="https://www.instagram.com/dra.nishalle.ramirez" target="_blank">dra.nishalle.ramirez</a>
                        </div>
                    </div>
                    <div className={ styles.contact }>
                        <p className={ styles.section }>Oficina</p>
                        <div className={ styles.field }>
                            <i className="fas fa-map-marker-alt"></i>
                            <div className={styles.paragraph}>
                                <p>Hospital Ángeles Lomas</p>
                                <p>Vialidad de la Barranca 240</p>
                                <p>Colonia Hacienda de las Palmas</p>
                                <p className={ styles.separation }>Huixquilucan, Estado De México</p>
                                <p>Torre de Consultorios</p>
                                <p>Piso 8</p>
                                <p>Consultorio 830</p>
                            </div>
                        </div>
                        <div className={ `${styles.field} ${ styles.map }` }>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.411669421022!2d-99.28420988461299!3d19.394611146918777!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d206cc5d563ffd%3A0xf70cdf4e5a254cee!2sHospital%20Angeles%20Lomas!5e0!3m2!1ses-419!2smx!4v1614741722662!5m2!1ses-419!2smx" allowFullScreen="" loading="lazy"></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <div className={ styles.footer }>
            </div>
        </div>
    )
}
export default DraRamirez